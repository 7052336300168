import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { Wordmark } from "./logo";
import { H5 } from "../../shared/headings";

const Copyright = () => {
  const year = new Date().getFullYear();
  return (
    <StyledCopyright>
      <Link to="/"><Wordmark aria-label="Copilot"/></Link>
      <H5>Supercharge your savings and investments</H5>
      <p>
        <span>&copy;</span> Copyright {year} Copilot&nbsp;Money,&nbsp;Inc.<br/>
        Apple Card is issued by Goldman Sachs Bank USA, Salt Lake City Branch.
      </p>
    </StyledCopyright>
  )
}

const StyledCopyright = styled.div`
  grid-column: col-start / span 12;
  text-align: center;
  ${props => props.theme.device.tablet} {
      grid-column: col-start / span 12;
      text-align: left;
      & h5 {
          max-width: 260px;
      }
      & svg {
          margin-top: 0;
      }
  }
  ${props => props.theme.device.laptop} {
      grid-column: col-start / span 6;
  }
  & p {
      font-weight: 600;
      // padding-top: ${props => props.theme.spacing[1]};
      color: ${props => props.theme.color.textSecondary};
      font-size: ${props => props.theme.fontSize.small};
      & span {
          font-size: 120%;
      }
      max-width: 340px;
      margin: 0 auto;
      ${props => props.theme.device.tablet} {
          margin: 0
      }
  }
  & svg {
      margin-bottom: ${props => props.theme.spacing[3]};
      margin-top: ${props => props.theme.spacing[1]};
      // width: 2rem;
  }
`

export default Copyright