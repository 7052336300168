import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";
import { useStaticQuery, graphql } from "gatsby";
import ogImage from "../../images/og-image.png";

const Seo = ({ title, ogTitle, description, image, article }) => {

  const { pathname } = useLocation()
  const { site } = useStaticQuery(query)
  const {
    defaultTitle,
    defaultOgTitle,
    defaultDescription,
    siteUrl,
    twitterUsername,
  } = site.siteMetadata

  const seo = {
    title: title || defaultTitle,
    ogtitle: ogTitle || defaultOgTitle,
    description: description || defaultDescription,
    image: image ? `${siteUrl}${image}` : `${siteUrl}${ogImage}`,
    url: `${siteUrl}${pathname}`,
  }
  
  return (
    <Helmet title={seo.title}>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <meta name="twitter:card" content="summary_large_image" />

      {seo.url &&           <meta property="og:url" content={seo.url} />}
      {article ? (
                            <meta property="og:type" content="article" />
      ) : (
                            <meta property="og:type" content="website" />
      )}
      {seo.ogtitle &&       <meta property="og:title" content={seo.ogtitle} />}
      {seo.description &&   <meta property="og:description" content={seo.description} />}
      {seo.image &&         <meta property="og:image" content={seo.image}/>}

      {twitterUsername &&   <meta name="twitter:creator" content={twitterUsername} />}
      {seo.ogtitle &&       <meta name="twitter:title" content={seo.ogtitle} />}
      {seo.description &&   <meta name="twitter:description" content={seo.description} />}
      {seo.image &&         <meta name="twitter:image" content={seo.image} />}
    </Helmet>
  )

}

export default Seo

Seo.propTypes = {
  title: PropTypes.string,
  ogtitle: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
}

Seo.defaultProps = {
  title: null,
  ogtitle: null,
  description: null,
  image: null,
  article: false,
}

const query = graphql`
  query Seo {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        defaultOgTitle: ogTitle
        siteUrl: siteUrl
        twitterUsername
      }
    }
  }
`
