import { H5 } from "../../shared/headings";
import { Link } from "gatsby";
import { OutboundLink } from "../../shared/Href";
import React from "react";
import styled from "styled-components";
// import { AnchorLink } from "gatsby-plugin-anchor-links";

const JUMP_LINKS = [
  { label: "Features", url: "#features" },
  { label: "Pricing", url: "#pricing" },
  { label: "Reviews", url: "#reviews" },
  { label: "Team", url: "#mission" },
]

const EXPLORE_LINKS = [
    { label: "Features", url: "/#features" },
    { label: "Pricing", url: "/#pricing" },
    { label: "Reviews", url: "/#reviews" },
    { label: "Team", url: "/#mission" },
]

const PRIVACY_AND_SEC_PATH = "/privacy-and-security";
const HELP_CENTER_PATH = "https://help.copilot.money/";
const CHANGELOG_PATH = "https://changelog.copilot.money/";
const INTELLIGENCE_PATH = "https://intelligence.copilot.money/";
const TERMS_OF_SERVICE_PATH = "/terms-of-service";
const PRIVACY_POLICY_PATH = "/privacy-policy";
const JOIN_TEAM_PATH = "https://careers.copilot.money"

const FooterLinks = ({ isIndexPage }) => {
  return (
    <>
        <OnThisPage>
            <H5>{ isIndexPage ? "On this page" : "Explore" }</H5>
            <LinkList>
                { isIndexPage && JUMP_LINKS.map((link, index) => (
                    <li key={link.label}><a href={link.url} title={link.label}>{link.label}</a></li>
                ))}
                { !isIndexPage && EXPLORE_LINKS.map((link, index) => (
                    <li key={link.label}><a href={link.url} title={link.label}>{link.label}</a></li>
                ))}
            </LinkList>
        </OnThisPage>
        <Elsewhere>
            <H5>Useful links</H5>
            <LinkList>
                <li key="privacy-and-security">
                    <Link to={PRIVACY_AND_SEC_PATH}>Privacy & Security</Link>
                </li>
                <li key="help-center">
                    <OutboundLink href={HELP_CENTER_PATH}>Help center</OutboundLink>
                </li>
                <li key="terms-of-service">
                    <Link to={TERMS_OF_SERVICE_PATH}>Terms of service</Link>
                </li>
                <li key="privacy-policy">
                    <Link to={PRIVACY_POLICY_PATH}>Privacy policy</Link>
                </li>
            </LinkList>
        </Elsewhere>
        <Elsewhere>
            <H5>More about Copilot</H5>
            <LinkList>
                <li key="intelligence">
                    <OutboundLink href={INTELLIGENCE_PATH}>Intelligence</OutboundLink>
                </li>
                <li key="changelog">
                    <OutboundLink href={CHANGELOG_PATH}>Changelog</OutboundLink>
                </li>
                <li key="join-team">
                    <OutboundLink href={JOIN_TEAM_PATH}>Careers</OutboundLink>
                </li>
            </LinkList>
        </Elsewhere>
    </>
  )
} 


const OnThisPage = styled.div`
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-top: ${props => props.theme.spacing[6]};
    grid-column: col-start / span 12;
    ${props => props.theme.device.tablet} {
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;
        text-align: left;
        grid-column: span 4;
    }
    ${props => props.theme.device.laptop} {
        grid-column: span 2;
    }
`
const Elsewhere = styled(OnThisPage)`
    ${props => props.theme.device.tablet} {
        grid-column: span 8;
    }
    ${props => props.theme.device.laptop} {
        grid-column: span 2;
    }
`
const LinkList = styled.ul`
    list-style-type: none;
    font-size: ${props => props.theme.fontSize.regular};
    font-weight: 600;
    line-height: 1.75;
    & li a {
        text-decoration: none;
        color: ${props => props.theme.color.textLink};
        &:hover {
            color: ${props => props.theme.color.textHighContrast};
        }
    }
`

export default FooterLinks