import Grid from "../../shared/grid";
import React from "react";
import styled from "styled-components";

import SpotlightSection from "./spotlight-section";
import DownloadCTA from "./download-cta";
import Copyright from "./copyright";
import ContactSection from "./contact-section";
import FooterLinks from "./footer-links";
import useMediaQuery from "../../shared/hooks/use-media-query";

const Footer = (props) => {
    const isTabletWidth = useMediaQuery(`(min-width: 768px)`)
    return (
        <FooterWrapper>
            <Grid>
                <FooterContainer>
                    <Featured isDownloadPage={props.isDownloadPage}>
                        { !isTabletWidth && !props.isDownloadPage && <DownloadCTA/> }
                        <SpotlightSection isDownloadPage={props.isDownloadPage} isLeaderboardPage={props.isLeaderboardPage}/>
                    </Featured>
                    <Main>
                        <Copyright/>
                        <FooterLinks isIndexPage={props.isIndexPage} />
                    </Main>
                    <Channels>
                        { isTabletWidth && !props.isDownloadPage && <DownloadCTA/> }
                        <ContactSection isDownloadPage={props.isDownloadPage} />
                    </Channels>
                </FooterContainer>
            </Grid>
        </FooterWrapper>
    )
}

const FooterWrapper = styled.div`
    box-shadow: inset 0 1px 0 rgba(48,137,255,0.2);
    z-index: ${props => props.isLeaderboardPage ? 1 : 0};
    @supports(backdrop-filter: opacity( 1.0 )) {
        backdrop-filter: saturate(180%) blur(20px);
        background: rgba(1, 9, 18, 0.7);
        background-image: none;
    }
`

const Featured = styled.div`
    margin-bottom: ${props => props.isDownloadPage ? "0px" : "56px"};
    grid-column: 1 / span 2;
    grid-row: 1 / span 1;
    ${props => props.theme.device.tablet} {
        grid-column: 2 / span 1;
        grid-row: 1 / span 1;
    }
    ${props => props.theme.device.laptop} {
        grid-column: 1 / span 2;
    }
`
const Main = styled.div`
    grid-column: 1 / span 2;
    grid-row: 2 / span 1;
    ${props => props.theme.device.tablet} {
        grid-column: 1 / span 1;
        grid-row: 1 / span 1;
    }
    ${props => props.theme.device.laptop} {
        grid-column: 1 / span 2;
        grid-row: 2 / span 1;
    }

    display: grid;
    font-size: inherit;
    grid-template-columns: repeat(12, [col-start] 1fr);
    margin: 0 auto;
    width: 100%;
    gap: 10px;
    ${props => props.theme.device.tablet} {
      gap: 20px;
    }
`
const Channels = styled.div`
    grid-column: 1 / span 2;
    grid-row: 3 / span 1;

    display: grid;
    font-size: inherit;
    grid-template-columns: repeat(12, [col-start] 1fr);
    margin: 0 auto;
    width: 100%;
    gap: 10px;
    ${props => props.theme.device.tablet} {
      gap: 20px;
    }
`

const FooterContainer = styled.div`
    grid-column: span 12;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: ${props => props.theme.spacing[8]} 0;
    ${props => props.theme.device.tablet} {
        padding: ${props => props.theme.spacing[10]} 0;
    }
    ${props => props.theme.device.laptop} {
    }
`

export default Footer
