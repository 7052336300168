import React from "react";

// #D1416B

export const ArrowUp = ({fill}) => {
  return(
    <svg width="36" height="36" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path style={{ mixBlendMode: "plus-lighter", mixBlendMode: "screen" }} fill={fill ? fill : "currentColor"} d="M10.9336 21.1348C16.5195 21.1348 21.1191 16.5352 21.1191 10.9492C21.1191 5.37305 16.5098 0.763672 10.9238 0.763672C5.34766 0.763672 0.748047 5.37305 0.748047 10.9492C0.748047 16.5352 5.34766 21.1348 10.9336 21.1348ZM10.9336 18.8984C6.5293 18.8984 2.99414 15.3535 2.99414 10.9492C2.99414 6.54492 6.51953 3 10.9238 3C15.3379 3 18.873 6.54492 18.8828 10.9492C18.8926 15.3535 15.3379 18.8984 10.9336 18.8984ZM13.9609 13.5859C14.4883 13.5859 14.8301 13.1953 14.8301 12.6289V8.12695C14.8301 7.39453 14.4102 7.0332 13.7168 7.0332H9.19531C8.61914 7.0332 8.24805 7.36523 8.24805 7.90234C8.24805 8.42969 8.61914 8.76172 9.20508 8.76172H10.7578L12.0078 8.61523L10.6211 9.86523L7.32031 13.166C7.13477 13.3516 7.01758 13.6055 7.01758 13.8594C7.01758 14.4355 7.42773 14.8066 7.97461 14.8066C8.26758 14.8066 8.50195 14.709 8.70703 14.5039L11.9883 11.2324L13.2383 9.85547L13.0918 11.1738V12.6289C13.0918 13.2148 13.4336 13.5859 13.9609 13.5859Z"/>
    </svg>
  )
}

export const ArrowRight = ({fill}) => {
  return (
    <svg width="36" height="36" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path style={{ mixBlendMode: "plus-lighter", mixBlendMode: "screen" }} fill={fill ? fill : "currentColor"} d="M10.9336 21.1348C16.5195 21.1348 21.1191 16.5352 21.1191 10.9492C21.1191 5.37305 16.5098 0.763672 10.9238 0.763672C5.34766 0.763672 0.748047 5.37305 0.748047 10.9492C0.748047 16.5352 5.34766 21.1348 10.9336 21.1348ZM10.9336 18.8984C6.5293 18.8984 2.99414 15.3535 2.99414 10.9492C2.99414 6.54492 6.51953 3 10.9238 3C15.3379 3 18.873 6.54492 18.8828 10.9492C18.8926 15.3535 15.3379 18.8984 10.9336 18.8984ZM15.9824 10.9492C15.9824 10.6465 15.875 10.4121 15.6309 10.168L12.3105 6.93555C12.125 6.75 11.9297 6.68164 11.666 6.68164C11.1777 6.68164 10.8262 7.04297 10.8262 7.54102C10.8262 7.79492 10.9434 8.0293 11.1094 8.17578L12.2422 9.25977L13.2969 10.0801L11.334 9.98242H6.7832C6.24609 9.98242 5.85547 10.3926 5.85547 10.9492C5.85547 11.4961 6.23633 11.9062 6.7832 11.9062H11.334L13.3066 11.8184L12.2617 12.6387L11.1094 13.7129C10.9336 13.8691 10.8262 14.0938 10.8262 14.3574C10.8262 14.8555 11.1777 15.2168 11.666 15.2168C11.9297 15.2168 12.125 15.1387 12.3105 14.9629L15.6309 11.7305C15.8652 11.5059 15.9824 11.252 15.9824 10.9492Z"/>
    </svg>
  )
}