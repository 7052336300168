import React from "react";
import styled, { css } from "styled-components";
import { H5 } from "../../shared/headings";
import { Link } from "gatsby";
import cssOutlineMixin from "../../shared/css-outline-mixin";
import { ArrowUp, ArrowRight } from "./arrow-svgs";

const Spotlight = ({destination, outbound, title, subtitle, Icon, gradientDirection }) => {

  const Children = () => {
    return (
      <Spot>
        <Text>
          <H5 style={{marginBottom: 0}}>{title}</H5>
          <p>{subtitle}</p>
        </Text>
        <Icon />
      </Spot>
    )
  }

  return (
    <SpotlightWrapper gradientDirection={gradientDirection}>
      {
        outbound ?
        (<a rel="noopener noreferrer" href={destination}><Children/></a>) :
        (<Link to={destination}><Children/></Link>)
      }
    </SpotlightWrapper>
  )

}

const SpotlightSection = ({ isDownloadPage, isLeaderboardPage }) => {
  return (
    <>
    { !isDownloadPage && (
      <Layout>
        <Spotlight 
            outbound 
            destination="https://developer.apple.com/news/?id=m1mmw99d" 
            title="Developer Spotlight" 
            subtitle="Get a look behind the scenes" 
            Icon={ArrowUp}
            gradientDirection="to right"
        />
        { isLeaderboardPage ? (
          <Spotlight 
            destination="/"
            title="Home"
            subtitle="Learn more about Copilot"
            Icon={ArrowRight}
          />
        ) : (
          <Spotlight 
              destination="/country-leaderboard"
              title="Country Leaderboard"
              subtitle="Cast your vote"
              Icon={ArrowRight}
          />
        )}
        <Spotlight
            outbound
            destination="https://copilotmoney.typeform.com/to/jW5VJx69"
            title="Offer Copilot to employees"
            subtitle="Fill out our interest form"
            Icon={ArrowUp}
            gradientDirection="to left"
        />
      </Layout>
    )}
    </>
  )
}

const gradientBackground = css`
  background: linear-gradient(${props => props.gradientDirection === "to right" ? '90deg' : '-90deg'}, #991D41 43.77%, #5D0FAE 150.08%);
  background-image: linear-gradient(${props => props.gradientDirection}, color(display-p3 0.605 0.082 0.309), color(display-p3 0.354 0 0.708));
`
const purpleBackground = css`
  background: #5D0FAE;
`

const Layout = styled.div`
  display: grid;
  font-size: inherit;
  grid-template-columns: repeat(12, [col-start] 1fr);
  margin: 0 auto;
  gap: 10px;
  ${props => props.theme.device.tablet} {
    gap: 20px;
  }
  ${props => props.theme.device.laptop} {
    margin-right: -20px;
    margin-left: -20px;
  }
`
const Spot = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`
const SpotlightWrapper = styled.div`
  grid-column: span 12;
  max-width: 550px;
  width: -moz-available; width: -webkit-fill-available;
  margin: 0 auto;
  ${props => props.theme.device.laptop} {
    grid-column: span 4;
  }
  & a {
    ${cssOutlineMixin};
    ${props => props.gradientDirection ? gradientBackground : purpleBackground};
    border-radius: 10px;
    padding: 18px 20px 32px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 4px;
    color: #D1416B;
    &:hover, &:hover p, &:hover h5 {
      color: #D2DAE4;
      mix-blend-mode: normal;
    }
  }
`
const Text = styled.div`
  & p {
    font-weight: 600;
    color: #D1416B;
    mix-blend-mode: screen;
    mix-blend-mode: plus-lighter;
  }
`

export default SpotlightSection