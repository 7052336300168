import { css } from "styled-components";

const cssOutlineMixin = css`
  position: relative;
  &:after {
    left: 0;
    top: 0;
    content: "";
    border-top: 1.5px solid ${props => props.theme.color.textPrimary};
    // box-shadow: inset 0px 1.5px 0px ${props => props.theme.color.textPrimary};
    border-radius: 10px;
    mix-blend-mode: overlay;
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none;
    color: transparent;
    box-sizing: border-box;
  }
`

export default cssOutlineMixin
