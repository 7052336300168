import React from "react";
import styled from "styled-components";
import { IconInstagram, IconTwitter } from "./social-icons";
import { OutboundLink } from "../../shared/Href";

const TWITTER_PROFILE = "https://twitter.com/copilotmoney";
const INSTAGRAM_PROFILE = "https://www.instagram.com/copilotmoney";
const CONTACT_EMAIL = "hello@copilot.money";

const MUTED_HOVER_OPTIONS = { y: -3, color: "#fff" };


const ContactSection = ({ isDownloadPage }) => {
  return (
    <StayInTouch fullWidth={isDownloadPage}>
      <Social>
          <OutboundLink 
              href={TWITTER_PROFILE}
              whileHover={MUTED_HOVER_OPTIONS}
              >
              <IconTwitter/>
          </OutboundLink>
          <OutboundLink
              href={INSTAGRAM_PROFILE}
              whileHover={MUTED_HOVER_OPTIONS}
              >
              <IconInstagram/>
          </OutboundLink>
      </Social>
      <Contact>
          <OutboundLink
              href={`mailto:${CONTACT_EMAIL}`}
              whileHover={MUTED_HOVER_OPTIONS}
              >
              {CONTACT_EMAIL}
          </OutboundLink>
      </Contact>
    </StayInTouch>
  )
}

const StayInTouch = styled.span`
    grid-column: col-start / span 12;
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 24px;
    margin-top: 24px;
    ${props => props.theme.device.tablet} {
        grid-column: ${props => props.fullWidth ? 'span 12' : 'span 6'};
        margin: 0;
        margin-top: 72px;
        text-align: right;
        flex-direction: ${props => props.fullWidth ? 'row' : 'row-reverse'};
        gap: 24px;
    }
    ${props => props.theme.device.laptop} {
        order: ${props => props.fullWidth ? '1' : '2'};
    }
`
const Social = styled.div`
    text-align: center;
    margin: 16px auto;
    color: ${props => props.theme.color.textLink};
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.6em;
    & a {
        display: inline;
    }
    & a:first-child {
        text-align: right;
    }
    & a:last-child {
        text-align: left;
    }
    ${props => props.theme.device.tablet} {
        margin-top: 24px;
        margin-right: 0;
        margin-left: 0;
    }
}
`
const Contact = styled.div`
    display: block;
    text-align: center;
    margin: 0 auto;
    & a {
        text-decoration: none;
        color: ${props => props.theme.color.textSecondary};
        font-size: ${props => props.theme.fontSize.regular};
        font-weight: 600;
        ${props => props.theme.device.laptop} {
            margin-left: 24px;
        }
    }

    ${props => props.theme.device.tablet} {
        display: inline;
        margin: 0;
        text-align: left;
        vertical-align: top;
    }
`

export default ContactSection